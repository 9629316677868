import * as React from "react";
/** @jsx jsx */
import {jsx, css} from "@emotion/react";
import ProjectLayout from "../../components/pages/project/ProjectLayout";
import styled from "@emotion/styled";
import HeaderBar from "../../components/pages/project/HeaderBar";
import jsonRequest from "../../utils/request/jsonRequest";
import {Checkbox, Col, Divider, Form, Input, message, Radio, Row, Select} from "antd";
import {useEffect, useMemo, useState} from "react";
import auth from "../../utils/auth";
import {useSelector} from "react-redux";
import * as queryString from "query-string";
import TeamInfo from "../../components/pages/project/TeamInfo";
import workStatus from "../../utils/options/workStatus";
import workTypes from "../../utils/options/workTypes";
import ProjectCard from "../../components/pages/project/ProjectCard";
import CardTitle from "../../components/pages/project/ProjectCard/CardTitle";
import ProjectForm from "../../components/pages/project/ProjectForm";
import DeliveryForm from "../../components/pages/project/DeliveryForm";
import {useRequest} from "ahooks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`

const Project = ({location}) => {
  auth.authorize()
  const {account} = useSelector((selector: any) => selector)
  const params = useMemo(() => {
    return queryString.parse(location.search)
  }, [location])
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)

  const {data: projectRate = 0} = useRequest(() => {
    return jsonRequest('/project/rate').then(rsp => rsp.data.data.projectRate)
  })

  const load = () => {
    if (loading || !params?.id) {
      return;
    }
    setLoading(true)
    jsonRequest(`/resume/${params.id}`).then(rsp => {
      if (rsp.success) {
        // setReadable(true)
        setData(rsp.data.data)
        if (!rsp.data.data.resumeSetting.resumeUpdatedAt && rsp.data.data.id === account.id) {
          message.warning('请完善个人履历')
        }
      } else {
        if (rsp.data.code === 1001) {
          // setReadable(false)
          message.warning('履历已隐藏')
        } else {
          message.error(rsp.message);
        }
      }
    }).catch(console.error).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    load();
  }, [])
  useEffect(() => {
    console.log(data)
  }, [data])

  return (<>
    <ProjectLayout loading={loading}>
      {
        !!data && (
          <Container>
            <HeaderBar backUrl='/personal/project'/>
            <TeamInfo
              id={data.id}
              avatar={data.avatar}
              name={data.nickname}
              title={data.resume.title}
              isShowContact={data.resumeSetting?.isShowContact}
              tags={[...(data.resume.region.acronym === 'CN' ? (data.city ? [data.city] : []) : [data.resume.region?.name]), `${data.resume.workYear}年经验`, workStatus[data.resume.workStatus] ?? data.resume.workStatus, workTypes[data.resume.workType] ?? data.resume.workType, ...(data.resume.job?.name ? [data.resume.job.name] : [])]}
              remoteWage={data.resume.remoteWage}
              siteWage={data.resume.siteWage}
              weChat={data.weChat}
              email={data.email}
              width={800}
              resume={data.resume}
              audited={data.resumeSetting?.auditStatus == 'auditSuccess'}
            />
            <ProjectCard>
              <ProjectForm projectRate={projectRate} contractorId={params.id as string} />
            </ProjectCard>
          </Container>
        )
      }
    </ProjectLayout>
  </>)
}

export default Project;
